import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import DateRangeFilter from "../../components/filterDropdown/DateRangeFilter";
import FilterSingleSelectDropdown from "../../components/filterDropdown/FilterSingleSelectDropdown";

export function ManageShippingsViewModel(logout) {
  const [filters, setFilters] = useState({
    customer: { value: -1, label: "All Customers" },
    startDate: null,
    endDate: null,
  });

  const [customers, setCustomers] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [shippings, setShippings] = useState({ data: [] });
  const [showFilters, setShowFilters] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getShippings = async function (search, page, filterArg) {
    setLoading(true);
    const payload = {
      page: page,
      filters: { ...filterArg, search: search },
    };
    let callResult = await makePostApiCall("fetchShippings", payload);

    if (callResult.success) {
      setShippings(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const getCustomersForDropdown = async function () {
    let callResult = await makePostApiCall(`getCustomersForDropdown`);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const resetFilters = () => {
    const filterReset = {
      customer: { value: -1, label: "All Customers" },
      startDate: null,
      endDate: null,
    };
    setFilters(filterReset);

    setShowFilters(false);
    getShippings(null, 1, filterReset);
  };

  const filterComponents = [
    <DateRangeFilter
      endDate={filters.endDate}
      onChange={(name, value) =>
        setFilters({
          ...filters,
          [name]: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={filters.startDate}
    />,
    <FilterSingleSelectDropdown
      header="Customers"
      onChange={(value) =>
        setFilters({
          ...filters,
          customer: value,
        })
      }
      options={customers}
      resetFilter={() =>
        setFilters({
          ...filters,
          customer: { value: -1, label: "All Customers" },
        })
      }
      valueName="customer"
      value={filters.customer}
    />,
  ];
  const deleteManufacturedProduct = async function (id) {
    let callResult = await makePostApiCall(`shippings/delete/${id}`);

    if (callResult.success) {
      const filtereditems = shippings.data.filter((item) => item.id !== id);
      setShippings({ ...shippings, data: filtereditems });

      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteShipping = function (shipping) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteManufacturedProduct(shipping.id)}
          message={`Are you sure you want to delete shipping for ${shipping.customer}, PO #: ${shipping.po_number}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    getShippings,
    loading,
    shippings,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    setShowFilters,
    showFilters,
    resetFilters,
    getCustomersForDropdown,
    filterComponents,
    filters,
    askDeleteShipping,
  };
}
