import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";

const initialFValues = {
  receiving_assembly_item_id: "",
  ingredient_amount_used: "",
  total_unit: "",
  receiving_item_total_used_unit: "",
  in_stock: "",
  pack_unit: "",
  unit_cost: "",
  product_name: "",
};

export default function AddAssemblyItemToProductForm(props) {
  const { assemblyItem, confirmAction, isEdit, setOpen, suggestedAmount } =
    props;

  console.log("suggestedAmount", suggestedAmount);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if (values.manual_entry === 0) {
      if ("ingredient_amount_used" in fieldValues) {
        temp.ingredient_amount_used = null;
        if (fieldValues.ingredient_amount_used === "") {
          temp.ingredient_amount_used = "Please fill out field.";
        }

        const totalStock = parseFloat(values.in_stock);
        const totalEditStock =
          parseFloat(values.in_stock) +
          parseFloat(values.ingredient_amount_used_old);

        const stockAmountToCheck = isEdit ? totalEditStock : totalStock;

        if (
          parseFloat(fieldValues.ingredient_amount_used) > stockAmountToCheck
        ) {
          temp.ingredient_amount_used = "Not enough in stock for this amount";
        }
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    var in_stock = assemblyItem.in_stock;
    var receiving_assembly_item_id = assemblyItem.receiving_assembly_item_id;
    var receiving_item_total_used_unit =
      assemblyItem.receiving_item_total_used_unit
        ? assemblyItem.receiving_item_total_used_unit
        : 0;
    var unit_of_measure = assemblyItem.unit_of_measure;
    var pack_unit = assemblyItem.pack_unit;
    if (assemblyItem.manual_entry) {
      in_stock = 0;
      receiving_assembly_item_id = 0;
      receiving_item_total_used_unit = 0;
      unit_of_measure = "";
      pack_unit = "n/a";
    }

    const ingredient_amount_used = isEdit
      ? assemblyItem.ingredient_amount_used
      : suggestedAmount;
    setValues({
      ...assemblyItem,
      in_stock: in_stock,
      receiving_assembly_item_id: receiving_assembly_item_id,
      receiving_item_total_used_unit: receiving_item_total_used_unit,
      unit_of_measure: unit_of_measure,
      pack_unit: pack_unit,
      ingredient_amount_used: ingredient_amount_used,
      is_used: false,
    });
  }, [assemblyItem]);

  const onSubmit = () => {
    if (validate()) {
      const ingredient_amount_used_old =
        values.ingredient_amount_used_old > 0
          ? values.ingredient_amount_used_old
          : 0;

      if (ingredient_amount_used_old !== values.ingredient_amount_used) {
        const newUsedWeight =
          parseFloat(values.receiving_item_total_used_unit) -
          parseFloat(ingredient_amount_used_old) +
          parseFloat(values.ingredient_amount_used);
        const newInStock =
          parseFloat(values.in_stock) +
          parseFloat(ingredient_amount_used_old) -
          parseFloat(values.ingredient_amount_used);

        const valuesCopy = {
          ...values,
          receiving_item_total_used_unit: Math.round(newUsedWeight * 100) / 100,
          in_stock: Math.round(newInStock * 100) / 100,
          is_used: true,
        };
        confirmAction(valuesCopy, isEdit);
        return;
      }
      setOpen({
        open: false,
      });
    } else {
    }
  };

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {assemblyItem.product_name}
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-6 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="In Stock"
                  name="in_stock"
                  onChange={handleInputChange}
                  value={`${values.in_stock} ${values.unit_of_measure}`}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.in_stock}
                />
              </div>
              <div>
                <Input
                  label="Cost"
                  name="unit_cost"
                  onChange={handleInputChange}
                  value={`$${values.unit_cost}`}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.unit_cost}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Pack Unit"
                  name="pack_unit"
                  onChange={handleInputChange}
                  value={`${
                    values.pack_unit ? values.pack_unit : values.in_stock
                  } ${values.unit_of_measure}`}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.pack_unit}
                />
              </div>
              <div>
                <Input
                  label={`${isEdit ? "Amount in use" : "Amount to use"} (${
                    values.unit_of_measure
                  })`}
                  name="ingredient_amount_used"
                  onChange={handleInputChange}
                  value={values.ingredient_amount_used}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.ingredient_amount_used}
                />
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                onClick={() => onSubmit()}
                className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
              >
                OK
              </button>
              <button
                onClick={() =>
                  setOpen({
                    open: false,
                  })
                }
                className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
