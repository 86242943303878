import { useEffect } from "react";
import { OrderDetailsViewModel } from "../../viewmodels/orders/OrderDetailsViewModel";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import CreateNewOrderForm from "../../components/forms/orders/CreateNewOrderForm";
import SimpleBanner from "../../components/SimpleBanner";

function OrderDetails(props) {
  const { logout } = props;

  const { orderId } = useParams();

  const {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateOrderApiCall,
    order,
    getOrderDetails,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    employeeOptions,
    getEmployeesForDropdown,
    printOrder,
    exportOrderForNetSuite,
  } = OrderDetailsViewModel(orderId, logout);

  const apiFunctionCalls = async function () {
    getEmployeesForDropdown([1, 2], [1, 6]);
    let callResult = await getCustomersForDropdown();
    if (callResult.success) {
      getOrderDetails();
    } else {
    }
  };

  useEffect(() => {
    apiFunctionCalls();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-8">
        <div className="sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Editing Order {order ? order.po_number : null}
            </h1>
          </div>
          <div className="mt-5">
            {order ? (
              <CreateNewOrderForm
                customers={customers}
                getMyProducts={getMyProducts}
                isEdit={true}
                loading={loading}
                order={order}
                setEmptyModelOpen={setEmptyModelOpen}
                showErrorModal={showErrorModal}
                updateOrderApiCall={updateOrderApiCall}
                onUpdateOrderItem={onUpdateOrderItem}
                deleteOrderItemApiCall={deleteOrderItemApiCall}
                addProductToOrderApiCall={addProductToOrderApiCall}
                employeeOptions={employeeOptions}
                printOrder={printOrder}
                exportOrderForNetSuite={exportOrderForNetSuite}
              />
            ) : null}
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
);
