import { ExportLabelViewModel } from "../../../viewmodels/qaReports/exportLabel/ExportLabelViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import EmptyModal from "../../../components/modals/EmptyModal";
import SimpleBanner from "../../../components/SimpleBanner";
import ExportLabelToPrint from "../../../components/forms/qaReports/exportLabel/ExportLabelToPrint";

function ExportLabel(props) {
  const { logout } = props;
  const {
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    openExportLabelDialog,
  } = ExportLabelViewModel(logout);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print px-4 sm:px-6 lg:px-4">
        <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={openExportLabelDialog}
            >
              Generate Export Label
            </button>
          </div>
        </div>
      </div>
      <ExportLabelToPrint
        certImage={
          "https://cool-api-new.ogwebdesign.ca/storage/brand-logos/06/fed.png"
        }
        label={11111}
      />
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportLabel)
);
