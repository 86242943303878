import React, { useEffect, useState } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";
import TextArea from "../../../controls/TextArea";

import ImportMediaDropzone from "../../../importMedia/ImportMediaDropzone";

import { config } from "../../../../constants";
import CreatableSelectMenu from "../../../controls/CreatableSelectMenu";
import { classNames } from "../../../../shared/utility";

const initialFValues = {
  plu: "",
  name_en: "",
  name_fr: "",
  description_en: "",
  description_fr: "",
  pro_group: "",
  ingredients_en: "",
  ingredients_fr: "",
  weight: "",
  shelf_life: "",
  price: "",
  barcode: "",
  storage_description_en: "",
  storage_description_fr: "",
  cooking_instructions_en: "",
  cooking_instructions_fr: "",
  allergens_en: "",
  allergens_fr: "",
};

export default function ProductDetailsForm(props) {
  const {
    allergensOptions,
    product,
    actionCall,
    isEdit,
    unitOfMeasureOptions,
  } = props;

  const [uploadedImage1, setUploadedImage1] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const [uploadedNutritionImage, setUploadedNutritionImage] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
  } = useForm(initialFValues, true, validate);
  console.log("values", values);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(product, values);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (isEdit && product) {
      setValues(product);
    }
  }, [isEdit, product, setValues]);

  const handleAllergensInput = (e) => {
    const newValues = e.target.value;
    var newAllergensEN = "";
    var newAllergensFR = "";
    newValues.forEach((element, index) => {
      if (index === 0) {
        newAllergensEN = element.label;
        newAllergensFR = element.label;
      } else {
        newAllergensEN += ", " + element.label;
        newAllergensFR += ", " + element.label;
      }
    });
    handleMultipleInputChange(
      { allergens: newValues },
      { allergens_en: newAllergensEN },
      { allergens_fr: newAllergensFR }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-between px-4">
        <div className={classNames(isEdit ? null : "divide-y divide-gray-500")}>
          <div
            className={classNames(
              isEdit ? "hidden" : null,
              "pt-6 pb-5 flex items-center justify-between flex-wrap sm:flex-nowrap"
            )}
          >
            <div className="mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Add new product
              </h3>
            </div>
          </div>

          {/* here */}
          <div className="space-y-3 pt-6 pb-5">
            <div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Plu"
                    name="plu"
                    onChange={handleInputChange}
                    value={values.plu}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Case Barcode"
                    name="barcode"
                    onChange={handleInputChange}
                    value={values.barcode}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Name (EN)"
                    name="name_en"
                    onChange={handleInputChange}
                    value={values.name_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Name (FR)"
                    name="name_fr"
                    onChange={handleInputChange}
                    value={values.name_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Description (EN)"
                    name="description_en"
                    onChange={handleInputChange}
                    value={values.description_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Description (FR)"
                    name="description_fr"
                    onChange={handleInputChange}
                    value={values.description_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <TextArea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Ingredients (EN)"
                    labelOn={true}
                    name="ingredients_en"
                    onChange={handleInputChange}
                    value={values.ingredients_en}
                    rows="3"
                  />
                </div>
                <div>
                  <TextArea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Ingredients (FR)"
                    labelOn={true}
                    name="ingredients_fr"
                    onChange={handleInputChange}
                    value={values.ingredients_fr}
                    rows="3"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Pro group"
                    name="pro_group"
                    onChange={handleInputChange}
                    value={values.pro_group}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-3 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Case weight"
                    name="weight"
                    onChange={handleInputChange}
                    value={values.weight}
                    labelOn={true}
                  />
                </div>
                <div>
                  <CreatableSelectMenu
                    handleInputChange={handleInputChange}
                    name="unit_of_measure"
                    options={unitOfMeasureOptions}
                    defaultValue={isEdit ? product.unit_of_measure : null}
                    title={"Case Unit of measure"}
                  />
                </div>
                <div>
                  <Input
                    label="Units in case"
                    name="unit_by_case"
                    onChange={handleInputChange}
                    value={values.unit_by_case}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-3 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Individual Unit Size"
                    name="individual_unit_size"
                    onChange={handleInputChange}
                    value={values.individual_unit_size}
                    labelOn={true}
                  />
                </div>
                <div>
                  <CreatableSelectMenu
                    handleInputChange={handleInputChange}
                    name="selling_unit_of_measure"
                    options={unitOfMeasureOptions}
                    defaultValue={
                      isEdit ? product.selling_unit_of_measure : null
                    }
                    title={"Selling Unit of measure"}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Unit Price"
                    name="price"
                    onChange={handleInputChange}
                    value={values.price}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Shelf life"
                    name="shelf_life"
                    onChange={handleInputChange}
                    value={values.shelf_life}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Storage description (EN)"
                    name="storage_description_en"
                    onChange={handleInputChange}
                    value={values.storage_description_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Storage description (FR)"
                    name="storage_description_fr"
                    onChange={handleInputChange}
                    value={values.storage_description_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <TextArea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Cooking instructions (EN)"
                    labelOn={true}
                    name="cooking_instructions_en"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_en}
                    rows="2"
                  />
                </div>
                <div>
                  <TextArea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Cooking instructions (FR)"
                    labelOn={true}
                    name="cooking_instructions_fr"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_fr}
                    rows="2"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <CreatableSelectMenu
                    isMulti={true}
                    handleInputChange={(e) => {
                      handleAllergensInput(e);
                    }}
                    name="allergens"
                    menuPlacement="top"
                    options={allergensOptions}
                    defaultValue={isEdit ? product.allergens : null}
                    title={"Allergens"}
                  />
                </div>
                <div>
                  <Input
                    label="Allergens (FR)"
                    name="allergens_fr"
                    disabled={true}
                    value={values.allergens_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-3 gap-4">
                <div className="grid-col-1">
                  <div className="flex space-x-2">
                    <label
                      htmlFor="image1"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Image 1
                    </label>
                  </div>
                  {uploadedImage1 ? (
                    <div>
                      <img
                        src={uploadedImage1}
                        height="200"
                        width="200"
                        alt="New logo"
                      />
                    </div>
                  ) : isEdit ? (
                    <img
                      src={config.url.STORAGE_URL + product.image1_url}
                      alt={product.name_en}
                      title={product.name_en}
                      className="media-image rounded-md"
                      height="200"
                      width="200"
                    />
                  ) : null}
                  <ImportMediaDropzone
                    fileName="image1"
                    handleInputChange={handleInputChange}
                    setUploadedImage={setUploadedImage1}
                  />
                </div>
                <div className="grid-col-1">
                  <div className="flex space-x-2">
                    <label
                      htmlFor="image2"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Image 2
                    </label>
                  </div>
                  {uploadedImage2 ? (
                    <div>
                      <img
                        src={uploadedImage2}
                        height="200"
                        width="200"
                        alt="New logo"
                      />
                    </div>
                  ) : isEdit ? (
                    product.image2_url ? (
                      <img
                        src={config.url.STORAGE_URL + product.image2_url}
                        alt={product.name_en}
                        title={product.name_en}
                        className="media-image rounded-md"
                        height="200"
                        width="200"
                      />
                    ) : null
                  ) : null}
                  <ImportMediaDropzone
                    fileName="image2"
                    handleInputChange={handleInputChange}
                    setUploadedImage={setUploadedImage2}
                  />
                </div>
                <div className="grid-col-1">
                  <div className="flex space-x-2">
                    <label
                      htmlFor="nutrition_image"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Nutrition Image
                    </label>
                  </div>
                  {uploadedNutritionImage ? (
                    <div>
                      <img
                        src={uploadedNutritionImage}
                        height="200"
                        width="200"
                        alt="New logo"
                      />
                    </div>
                  ) : isEdit ? (
                    <img
                      src={config.url.STORAGE_URL + product.nutrition_image_url}
                      alt={product.name_en}
                      title={product.name_en}
                      className="media-image rounded-md"
                      height="200"
                      width="200"
                    />
                  ) : null}
                  <ImportMediaDropzone
                    fileName="nutrition_image"
                    handleInputChange={handleInputChange}
                    setUploadedImage={setUploadedNutritionImage}
                  />
                </div>
              </div>
            </div>

            <div className="sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
