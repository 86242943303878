import React from "react";
import SelectMenu from "../../../controls/SelectMenu";

export default function ManufacturingRecipeSelect(props) {
  const { onFetchRecipeItems, recipes } = props;

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 gap-2">
        <div className="col-span-1">
          <SelectMenu
            handleInputChange={(e) => onFetchRecipeItems(e.target.value.value)}
            name="selected_recipe"
            options={recipes}
            // defaultValue={isEdit ? manufacturedProduct.supervisor : null}
            title={"Recipe"}
          />
        </div>
      </div>
    </div>
  );
}
