import { useEffect } from "react";
import { useForm } from "../../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Input from "../../../controls/Input";
import { ingestBarcodeScan } from "../../../../shared/utility";
import CreatableSelectMenu from "../../../controls/CreatableSelectMenu";
import SelectMenu from "../../../controls/SelectMenu";

const initialFValues = {
  manufacturer_plu: "",
  product_name: "",
  pack_unit: "",
  cost: "",
  certification_type: "",
  allergens: [],
  product_type: "",
  is_non_meat: "",
  preservatives: [],
  claims: [],
  ingredient_id: { value: -1, label: "" },
};

const isNonMeatOptions = [
  { value: 0, label: "Select option" },
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
];

const taxTypeOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "HST" },
];

export default function AssemblyItemDetailsForm(props) {
  const {
    actionCall,
    isEdit,
    index,
    certifiedByOptions,
    manufacturersOptions,
    preservativeOptions,
    allergensOptions,
    claimsOptions,
    assemblyItem,
    myIngredientsOptions,
    productTypeOptions,
    setOpen,
    unitOfMeasureOptions,
    countryOptions,
  } = props;

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
  } = useForm(initialFValues, false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("ingredient_id" in fieldValues) {
      temp.ingredient_id = null;
      if (fieldValues.ingredient_id.value === -1) {
        temp.ingredient_id = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (isEdit) {
        actionCall(assemblyItem, values, index);
      } else {
        actionCall(values);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setValues(assemblyItem);
    }
  }, [assemblyItem, setValues]);

  const barcodeScanned = (e) => {
    const scannedString = e.target.value;
    const barcodeInfo = ingestBarcodeScan(scannedString);
    handleMultipleInputChange({ manufacturer_plu: barcodeInfo.plu });
  };

  console.log("myIngredientsOptions", myIngredientsOptions);
  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Add new supplier item
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Scan Barcode"
                  name="barcode"
                  onChange={(e) => barcodeScanned(e)}
                  value={values.barcode}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <CreatableSelectMenu
                  handleInputChange={handleInputChange}
                  name="manufacturer"
                  options={manufacturersOptions}
                  defaultValue={isEdit ? assemblyItem.manufacturer : null}
                  title={"Manufacturer"}
                />
              </div>
              <div>
                <Input
                  label="Manufacturer PLU/SKU/CODE"
                  name="manufacturer_plu"
                  onChange={handleInputChange}
                  value={values.manufacturer_plu}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Product name"
                  name="product_name"
                  onChange={handleInputChange}
                  value={values.product_name}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="ingredient_id"
                  options={myIngredientsOptions}
                  title={"BOM item"}
                  defaultValue={isEdit ? assemblyItem.ingredient_id : null}
                  error={errors.ingredient_id}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-3 gap-4">
              <div>
                <Input
                  label="Pack Unit"
                  name="pack_unit"
                  onChange={handleInputChange}
                  value={values.pack_unit}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <CreatableSelectMenu
                  handleInputChange={handleInputChange}
                  name="unit_of_measure"
                  options={unitOfMeasureOptions}
                  defaultValue={isEdit ? assemblyItem.unit_of_measure : null}
                  title={"Unit of measure"}
                />
              </div>
              <div>
                <Input
                  label="Unit Cost"
                  name="cost"
                  onChange={handleInputChange}
                  value={values.cost}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <CreatableSelectMenu
                  handleInputChange={handleInputChange}
                  name="certification_type"
                  options={certifiedByOptions}
                  defaultValue={isEdit ? assemblyItem.certification_type : null}
                  title={"Certified by"}
                />
              </div>
              <div>
                <CreatableSelectMenu
                  isMulti={true}
                  handleInputChange={handleInputChange}
                  name="allergens"
                  menuPlacement="top"
                  options={allergensOptions}
                  defaultValue={isEdit ? assemblyItem.allergens : null}
                  title={"Allergens"}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="is_non_meat"
                  menuPlacement="top"
                  options={isNonMeatOptions}
                  defaultValue={isEdit ? assemblyItem.is_non_meat : null}
                  title={"Is Meat"}
                  showLabel={true}
                  placeholder=""
                />
              </div>
              <div>
                <CreatableSelectMenu
                  handleInputChange={handleInputChange}
                  name="product_type"
                  menuPlacement="top"
                  options={productTypeOptions}
                  defaultValue={isEdit ? assemblyItem.product_type : null}
                  title={"Product Type"}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <CreatableSelectMenu
                  isMulti={true}
                  handleInputChange={handleInputChange}
                  name="preservatives"
                  menuPlacement="top"
                  options={preservativeOptions}
                  defaultValue={isEdit ? assemblyItem.preservatives : null}
                  title={"Preservatives"}
                />
              </div>
              <div>
                <CreatableSelectMenu
                  isMulti={true}
                  handleInputChange={handleInputChange}
                  name="claims"
                  menuPlacement="top"
                  options={claimsOptions}
                  defaultValue={isEdit ? assemblyItem.claims : null}
                  title={"Claims"}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="tax_type"
                  options={taxTypeOptions}
                  title={"Tax type"}
                  defaultValue={isEdit ? assemblyItem.tax_type : null}
                  error={errors.tax_type}
                  showLabel={true}
                  placeholder=""
                />
              </div>
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="export_eligibility"
                  options={countryOptions}
                  title={"Export Eligibility"}
                  defaultValue={isEdit ? assemblyItem.export_eligibility : null}
                  error={errors.export_eligibility}
                  showLabel={true}
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
