import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../shared/makeApiCall";
// import AllergensForm from "../../components/forms/setup/allergens/AllergensForm";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import ImportMediaCsvDropzone from "../../components/importMedia/ImportMediaCsvDropzone";
import CostcoSkuForm from "../../components/forms/setup/costcoSku/CostcoSkusForm";

export function CostcoPlusViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getSkus = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`costcoSkus`, payload);
    setLoading(false);
    if (callResult.success) {
      setSkus(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createSku = async function (values) {
    const payload = { ...values };
    let callResult = await makePostApiCall("createCostcoSku", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Costco Sku added",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSkus("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateSku = async function (sku, values, index) {
    let callResult = await makePutApiCall(`costcoSkus/${sku.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Costco Sku updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSkus("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddSkuSlideOver = () => {
    setSlideOver({
      childComponent: (
        <CostcoSkuForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createSku}
        />
      ),
      open: true,
    });
  };

  const viewSkuDetails = async function (sku, index) {
    setSlideOver({
      childComponent: (
        <CostcoSkuForm
          sku={sku}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateSku}
        />
      ),
      open: true,
    });
  };

  const deleteCostcoSku = async function (id, index) {
    setLoading(true);

    let callResult = await makePostApiCall(`deleteCostcoSku/${id}`, {});
    setLoading(false);
    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      getSkus(null, 0);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askdeleteCostcoSku = function (sku, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteCostcoSku(sku.id, index)}
          message={`Are you sure you want to delete  ${sku.costco_sku}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const uploadOrderCsv = async function (uploadedFile) {
    const { name, value } = uploadedFile.target;
    const payload = { [name]: value };
    let callResult = await makePostApiCall(
      "importCostcoOrder",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      window.location = `/orders/${callResult.data.order_id}`;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openImportOrder = () => {
    setEmptyModelOpen({
      childComponent: (
        <ImportMediaCsvDropzone
          fileName="orderCsv"
          actionCall={uploadOrderCsv}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return {
    askdeleteCostcoSku,
    emptyModal,
    getSkus,
    loading,
    skus,
    openAddSkuSlideOver,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewSkuDetails,
    openImportOrder,
  };
}
