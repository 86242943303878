import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../useForm";

import SelectMenu from "../../controls/SelectMenu";
import {
  convertToDefEventPara,
  makeBeepNoise,
  shippingStatusOptions,
  updateObjectInArray,
} from "../../../shared/utility";
import OrderItemsForShipping from "./OrderItemsForShipping";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import { classNames } from "primereact/utils";
import Input from "../../controls/Input";

const initialFValues = {
  id: 0,
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_id: 0,
  orderItems: [],
};

export default function CreateNewShippingForm(props) {
  const {
    orders,
    onFetchOrderItems,
    isEdit,
    loading,
    shipping,
    setEmptyModelOpen,
    showErrorModal,
    onFetchPackagedItemByBarcode,
    updateShippingApiCall,
    createShippingApiCall,
    addPackagedItemToOrderItem,
    deleteShippingItemApiCall,
    addSkidLabelToOrderItem,
    employeeOptions,
    deleteShipping,
    printShipping,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);

  const barcodeInputRef = useRef(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.order_id = null;
    if (values.order_id === 0) {
      temp.order_id = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (shipping) {
      console.log("shipping.status", shipping.status);
      const shippingCopy = {
        ...shipping,
        status: shippingStatusOptions.filter(
          (option) => option.value == shipping.status
        )[0],
      };
      console.log("shippingCopy", shippingCopy);
      setValues(shippingCopy);
    }
  }, [shipping]);

  function findIndexForOrderItem(product_id) {
    return values.orderItems.findIndex(
      (element) => element.product_id === product_id
    );
  }

  const updateShipping = () => {
    if (validate()) {
      updateShippingApiCall(values);
    }
  };

  const createShipping = () => {
    if (isEdit) {
      updateShipping();
    } else {
      if (validate()) {
        createShippingApiCall(values);
      }
    }
  };

  const normalItemScanned = async function (packagedItem, itemIndex) {
    console.log("normalItemScanned-packagedItem", packagedItem);
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[itemIndex];

    const packageAlreadyScanned = orderItem.shippingItems.findIndex(
      (element) => {
        return element.id === packagedItem.id;
      }
    );
    if (packageAlreadyScanned >= 0) {
      showErrorModal("Item already scanned");
      return;
    }

    let callResult = { success: false };
    if (isEdit) {
      callResult = await addPackagedItemToOrderItem(
        values.id,
        orderItem.id,
        packagedItem
      );
    }

    if (callResult.success || !isEdit) {
      let newShippingItems = [packagedItem];
      if (orderItem.shippingItems) {
        newShippingItems = [...orderItem.shippingItems, packagedItem];
      }
      const shipped_amount = orderItem.shipped_amount
        ? parseInt(orderItem.shipped_amount)
        : 0;

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount: shipped_amount + 1,
        shippingItems: newShippingItems,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
  };

  const skidLabelScanned = async function (packagedItems, itemIndex) {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[itemIndex];

    // 5448559175

    console.log("packagedItems.packaged_item", packagedItems.packaged_item);
    console.log("orderItem.shippingItems", orderItem.shippingItems);
    var newItems = packagedItems.packaged_item.filter(function (obj) {
      return (
        orderItem.shippingItems.findIndex((item) => item.id === obj.id) === -1
      );
    });
    console.log("newItems", newItems);

    let callResult = { success: false };
    if (isEdit) {
      callResult = await addSkidLabelToOrderItem(
        values.id, // shipping id
        orderItem.id,
        newItems.map((item) => item.id)
      );
    }

    if (callResult.success || !isEdit) {
      let newShippingItems = [...newItems];
      if (orderItem.shippingItems) {
        newShippingItems = [...orderItem.shippingItems, ...newItems];
      }
      const shipped_amount = orderItem.shipped_amount
        ? parseInt(orderItem.shipped_amount)
        : 0;

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount: shipped_amount + packagedItems.count,
        shippingItems: newShippingItems,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    setValues({
      ...values,
      scanned_box_label: "",
    });
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
    let callResult = await onFetchPackagedItemByBarcode(scannedBarcode);
    if (callResult.success) {
      makeBeepNoise();
      const packagedItem = callResult.data;
      var prodIdToSearch = packagedItem.packaged_item.product_id;
      if (packagedItem.is_skid_label) {
        if (packagedItem.packaged_item.length > 0) {
          prodIdToSearch = packagedItem.packaged_item[0].product_id;
        } else {
          showErrorModal("No items for skid label");
          return;
        }
      }
      const itemIndex = findIndexForOrderItem(prodIdToSearch);

      if (itemIndex >= 0) {
        if (packagedItem.is_skid_label) {
          console.log("itemIndex", itemIndex);
          skidLabelScanned(packagedItem, itemIndex);
        } else {
          normalItemScanned(packagedItem.packaged_item, itemIndex);
        }
      } else {
        showErrorModal("Item not in order");
      }
    } else {
      showErrorModal("We could not find that item.");
    }
  };

  const makeDeleteShippingItemApiCall = async function (
    id,
    barcode,
    product_id
  ) {
    var onItemDeleted = { success: false };

    const itemIndex = findIndexForOrderItem(product_id);

    if (isEdit) {
      onItemDeleted = await deleteShippingItemApiCall(id);
    }

    if (onItemDeleted.success || !isEdit) {
      if (itemIndex >= 0) {
        const orderItems = [...values.orderItems];
        const orderItem = orderItems[itemIndex];

        const filteredItems = orderItem.shippingItems.filter((element) => {
          return element.shipping_barcode !== barcode;
        });
        const orderItemBeingEdited = {
          ...orderItem,
          shipped_amount: parseInt(orderItem.shipped_amount) - 1,
          shippingItems: filteredItems,
        };
        var payload = {
          index: itemIndex,
          item: orderItemBeingEdited,
        };
        const newOrderItems = updateObjectInArray(orderItems, payload);
        setValues({ ...values, orderItems: newOrderItems });
        setEmptyModelOpen({
          childComponent: null,
          open: false,
        });
      }
    }
  };

  const askDeleteShippingItem = function (product) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() =>
            makeDeleteShippingItemApiCall(
              product.id,
              product.shipping_barcode,
              product.product_id
            )
          }
          message={`Are you sure you want to delete ${product.product_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  console.log("values", values);
  return (
    <div className="grid grid-col-1 sm:grid-cols-3 gap-3 h-full">
      <div className="sm:col-span-2 card-custom p-3 order-2 sm:order-1">
        <div>
          <div className="flex space-x-2 mb-1">
            <label
              htmlFor="scanned_box_label"
              className={"text-sm block font-medium text-gray-900"}
            >
              Scan barcode
            </label>
          </div>
          <input
            placeholder="Scan box label"
            name="scanned_box_label"
            disabled={barcodeScanInProgress}
            ref={barcodeInputRef}
            onChange={handleInputChange}
            value={values.scanned_box_label}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleBarcodeScan(e.target.value);
            }}
            type="search"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <OrderItemsForShipping
          askDeleteShippingItem={askDeleteShippingItem}
          loading={false}
          orderItems={values.orderItems}
          setEmptyModelOpen={setEmptyModelOpen}
        />
      </div>
      <div className="space-y-5 order-1 sm:order-2">
        <div className="card-custom p-3 space-y-2">
          <SelectMenu
            handleInputChange={(e) => {
              handleInputChange(
                convertToDefEventPara("order_id", e.target.value)
              );
              onFetchOrderItems(e.target.value.value);
            }}
            name="order_id"
            options={orders}
            defaultValue={isEdit ? shipping.order : null}
            title={"Order to ship"}
            disabled={isEdit}
            error
          />

          <SelectMenu
            handleInputChange={handleInputChange}
            name="created_by"
            options={employeeOptions}
            defaultValue={isEdit ? shipping.created_by : null}
            title={"Employee"}
            error={errors.created_by}
            showLabel={true}
          />

          <Input
            label="Invoice #"
            name="invoice_number"
            onChange={handleInputChange}
            value={values.invoice_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.invoice_number}
          />

          <Input
            label="Seal #"
            name="seal_number"
            onChange={handleInputChange}
            value={values.seal_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.seal_number}
          />

          <SelectMenu
            handleInputChange={handleInputChange}
            name="status"
            options={shippingStatusOptions.filter(
              (option) => option.value !== -1
            )}
            value={values.status}
            title={"Status"}
            error={errors.status}
            showLabel={true}
          />
          <div className="flex flex-shrink-0 py-4">
            <button
              type="button"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "inline-flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 mr-4"
              )}
              onClick={() => printShipping()}
            >
              Print
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={createShipping}
            >
              {values.id > 0 ? "Save" : "Submit"}
            </button>
            <button
              type="submit"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "ml-4 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              )}
              onClick={deleteShipping}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
