export default function ExportLabelToPrint(props) {
  const { certImage, label } = props;

  return (
    <div className="export-label-section-to-print border-2 border-gray-500 text-center ml-auto mr-auto">
      <img
        src={certImage}
        alt="Certification image"
        width="200px"
        height="200px"
        className="ml-auto mr-auto"
      />

      <p className="mt-2 text-red-500">Cert. No. Cert</p>

      <p className="mt-2 text-2xl">{label}</p>
    </div>
  );
}
